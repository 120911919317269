/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { Observable, ReplaySubject, EMPTY } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { QueryService } from '@services/query/query.service';

export interface UserInfo {
    LB_NOM: string;
    LB_PRENOM: string;
    LB_JURISDICTION_LVL: string;
    LB_STRUCTURE?: string;
    FL_SUPER_USER: boolean;
    FL_STRUCTURE_KO: boolean;
    NB_DAYS_UNTIL_RESET: number;
}

export enum JurisdictionLevel {
    A = 'A',
    G = 'G',
    E = 'E',
    D = 'D',
    C = 'C',
    T = 'T'
}

@Injectable()
export class AuthService {
    private _username = '';
    private _jurisdictionLevel: JurisdictionLevel;
    private _mainLabelStructure = '';
    private _isSuperUser = false;
    private _userHasKOStructure = false;
    private readonly _authenticated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _nbDaysUntilReset = -1;
    private readonly _nbDaysUntilResetObs: ReplaySubject<number> = new ReplaySubject<number>(null);

    constructor(private readonly _queryService: QueryService) {}

    get username(): string { return this._username; }
    get jurisdictionLevel(): string { return this._jurisdictionLevel; }
    get mainLabelStructure(): string { return this._mainLabelStructure; }
    get isSuperUser(): boolean { return this._isSuperUser; }
    get hasKOStructure(): boolean { return this._userHasKOStructure; }
    get authenticated(): Observable<boolean> { return this._authenticated.asObservable(); }
    get nbDaysUntilReset(): number { return this._nbDaysUntilReset; }
    get nbDaysUntilResetObs(): Observable<number> { return this._nbDaysUntilResetObs.asObservable(); }

    public authenticate(username: string, password: string): Observable<void> {
        return this._queryService.authenticate(username, password).pipe(
            switchMap(() => this.getUserInfos())
        );
    }

    public logout(): void {
        this._queryService.logout().subscribe(() => {
            this._username = '';
            this._queryService.resetToken();
            this._authenticated.next(false);
            window.location.reload();
        }, error => isDevMode() && console.log('Logout error', error));
    }

    public getUserInfos(): Observable<void> {
        if (this._username) {
            return EMPTY;
        }
        const template = 'get_userinfos.tpl';
        this._queryService.flushQuery(template);
        return this._queryService.getSqlResult(template).pipe(
            map((data: UserInfo[]) => {
                if (data && data.length > 0) {
                    this._username = `${data[0].LB_PRENOM.toUpperCase()} ${data[0].LB_NOM.toUpperCase()}`;
                    this._mainLabelStructure = data[0].LB_STRUCTURE;
                    this._jurisdictionLevel = JurisdictionLevel[data[0].LB_JURISDICTION_LVL];
                    this._isSuperUser = !!data[0].FL_SUPER_USER;
                    this._userHasKOStructure = !!data[0].FL_STRUCTURE_KO;
                    this._authenticated.next(true);
                    this._nbDaysUntilReset = data[0].NB_DAYS_UNTIL_RESET;
                    this._nbDaysUntilResetObs.next(this._nbDaysUntilReset);
                }
            })
        );
    }

    public resetPasswordRequest(login: string): Observable<void> {
        return this._queryService.resetPasswordRequest(login);
    }

    public resetPasswordValidationRequest(username: string, resetCode: string, newPassword: string): Observable<void> {
        return this._queryService.resetPasswordValidation(username, resetCode, newPassword);
    }

    public resetUserInfos(): void {
        this._username = '';
        this._mainLabelStructure = '';
        this._jurisdictionLevel = undefined;
        this._isSuperUser = false;
        this._userHasKOStructure = false;
        this._authenticated.next(false);
        this._nbDaysUntilReset = -1;
        this._nbDaysUntilResetObs.next(this._nbDaysUntilReset);
    }
}
