/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularSlickgridModule, GridOption } from 'angular-slickgrid';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from './shared.module';
import { RoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { ViewComponent } from './view/view.component';
import { ResetPasswordRequestComponent } from './view/reset-password-request/reset-password-request.component';

import { ComponentGeneratorService } from '@services/component-generator/component-generator.service';
import { ConfigService } from '@services/config/config.service';


const slickOptions: Partial<GridOption> = {
    rowHeight: 23,
    enableGridMenu: false,
    enableHeaderMenu: false
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RoutingModule,
        AngularSlickgridModule.forRoot(slickOptions),
        TranslateModule.forRoot(),
        SharedModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        ViewComponent,
        ResetPasswordRequestComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => () => config.init(),
            deps: [ConfigService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => () => config.initColumnFormatters(),
            deps: [ConfigService],
            multi: true
        },
        ComponentGeneratorService,
        ConfigService
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
