/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Column, GridOption, CurrentSorter, SortDirectionNumber, SortDirection } from 'angular-slickgrid';
import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of, forkJoin } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { SlickgridFormatterService } from '@services/slickgrid-formatter/slickgrid-formatter.service';
import { GridConfsCollection, GridConf, ColumnDef, GridFromDB, GridOptions } from './generic-grid-typings';
import { switchMap, first, delay } from 'rxjs/operators';
import { ConfigService } from '@services/config/config.service';
import { GenericGridClickService } from './generic-grid-click.service';
import { LanguageService } from '@services/language/language.service';

enum PageSizeEnum {
    LIMITED = 10,
    SMALL = 25,
    AVERAGE = 50,
    LARGE = 100
}

@Injectable({
    providedIn: 'root'
})
export class GenericGridService {
    public gridDataSub: Subject<Object[]> = new Subject();
    public gridDataLimitSub: Subject<number> = new Subject();
    public gridConf: GridConfsCollection = {};
    public gridId: number[];

    private readonly _queryTemplateGrid: string;
    private readonly _pageSizes: Array<number> = [
        PageSizeEnum.LIMITED,
        PageSizeEnum.SMALL,
        PageSizeEnum.AVERAGE,
        PageSizeEnum.LARGE
    ];
    private readonly _defaultPageSize: number = this._pageSizes[0];
    private readonly _multipleDataRequestDelay = 500;

    constructor(private readonly _queryService: QueryService,
                private readonly _filterConfigService: FilterConfigService,
                private readonly _slickgridFormaterService: SlickgridFormatterService,
                private readonly _clickService: GenericGridClickService,
                private readonly _configService: ConfigService,
                private readonly _languageService: LanguageService,
                private readonly _translateService: TranslateService) {
        this._queryTemplateGrid = this._configService.templateGetGridConf;
        this._onFilterApply();
    }

    public getColumnDefinitions(gridId: number): Observable<Column[]> {
        return new Observable<Column[]>(observer => {
            this._fetchGridConf(gridId).subscribe(value => {
                if (value) {
                    observer.next(this._buildColumns(value));
                } else {
                    if (isDevMode()) {
                        console.error('No grid conf found for id : ' + gridId);
                    }
                    observer.next([]);
                }
            });
        });
    }

    public getGridData(): Observable<Object[]> {
        return this.gridDataSub;
    }

    public getGridDataLimit(): Observable<number> {
        return this.gridDataLimitSub;
    }

    public getPaginedGridOptions(): GridOption {
        const gridOptions: GridOption = {};
        this.initPaginationOptions(gridOptions);
        return gridOptions;
    }

    public initPaginationOptions(opt: GridOption): void {
        opt.enablePagination = true;
        opt.pagination = {
            pageSizes: this._pageSizes,
            pageSize: this._defaultPageSize
        };
    }

    public getColumnOrder(gridId: number): CurrentSorter[] {
        return this.gridConf[gridId].columns.reduce((acc: CurrentSorter[], col: ColumnDef) => {
            if (typeof col.direction !== 'undefined') {
                acc.push({
                    columnId: col.field,
                    direction: SortDirectionNumber[col.direction] as SortDirection
                });
            }
            return acc;
        }, []);
    }

    public fetchGridData(): void {
        const limit = false;
        this._fetchGridData(this._filterConfigService.buildValuesHashmap(), limit)
            .subscribe({
                next: this._applyGridResult.bind(this),
                error: this._applyGridResult.bind(this, [])
            });
    }

    public buildAllColumns(gridId: number, displayable: boolean, csvExport = false): Column[] {
        return this._buildColumns(this.gridConf[gridId], displayable, csvExport);
    }

    private _onFilterApply(): void {
        this._filterConfigService.onApplyGrid.subscribe(filterValues => {
            this._fetchGridData(filterValues).pipe(first()).subscribe({
                next: this._applyGridResult.bind(this),
                error: this._applyGridResult.bind(this, [[]])
            });
        });
    }

    private _applyGridResult(data: Object[][]): void {
        let cardinality: number;
        const results: Object[] = [];
        for (let i = 0; i < data.length; ++i) {
            if (data[i].length === 1 && typeof data[i][0] === 'number') {
                if (typeof cardinality === 'undefined' || cardinality < data[i][0]) {
                    cardinality = data[i][0] as number;
                }
            } else {
                results.push({
                    gridId: this.gridId[i],
                    data: data[i]
                });
            }
        }

        if (typeof cardinality !== 'undefined') {
            this.gridDataLimitSub.next(cardinality);
        } else {
            this.gridDataSub.next(results);
        }
    }

    private _fetchGridConf(gridId: number): Observable<GridConf> {
        if (this.gridConf[gridId]) {
            return of(this.gridConf[gridId]);
        }
        const queryParams: {id_grid: number} = {id_grid: gridId};
        return this._queryService.getSqlResult(this._queryTemplateGrid, queryParams).pipe(
            first(),
            switchMap((grid: GridFromDB[]) => of(this._buildGridConf(grid[0])))
        );
    }

    private _buildGridConf(grid: GridFromDB): GridConf {
        if (!grid) {
            return;
        }
        const opt: GridOptions = new GridOptions(grid.GRID_OPT);
        const gridConf: GridConf = {
            id: Number(grid.ID_GRID),
            name: grid.LB_GRID,
            title: opt.title,
            filterGroups: [grid.ID_FLTR_GRP],
            columns: JSON.parse(grid.GRID_COLUMNS),
            template: opt.template,
            limit: opt.limit
        };
        this.gridConf[gridConf.id] = gridConf;
        return gridConf;
    }

    private _fetchGridData(filters?: Object, limit = true): Observable<Object[]> {
        let queryParams: Object = {};
        const cdLang: Object = { CD_LANG: this._languageService.currentLang };
        if (typeof filters !== 'undefined') {
            queryParams = filters;
        }
        Object.assign(queryParams, cdLang);
        const queries: Observable<Object[]>[] = [];
        this.gridId.forEach((gridId, index) => {
            let queryLimit: number;
            if (limit) {
                queryLimit = this.gridConf[gridId].limit || this._configService.rowsLimit;
            }
            if (index === 0) {
                queries.push(this._queryService.getSqlResult(this.gridConf[gridId].template, queryParams, queryLimit));
            } else {
                queries.push(
                    of('delay').pipe(delay(index * this._multipleDataRequestDelay), switchMap(
                        () => this._queryService.getSqlResult(this.gridConf[gridId].template, queryParams, queryLimit)
                    )));
            }
        });
        return forkJoin(queries);
    }

    private _buildColumns(gridConf: GridConf, displayable = true, csvExport = false): Column[] {
        return gridConf.columns
            .filter((colDef: ColumnDef) => {
                if ((typeof colDef.display === 'undefined' || colDef.display === false) && displayable) {
                    return false;
                }
                if (typeof colDef.excel_export !== 'undefined' && colDef.excel_export === false && !displayable) {
                    return false;
                }
                return true;
            })
            .map((colDef: ColumnDef) => {
                let i18nKey = '';
                if (typeof colDef.label_excel_export !== 'undefined' && !displayable) {
                    i18nKey = colDef.label_excel_export;
                } else if (colDef.label === undefined) {
                    i18nKey = `_CATALOG_._${colDef.field}_`;
                } else {
                    i18nKey = colDef.label;
                }
                const col: Column = {
                    id: colDef.field,
                    name: this._translateService.instant(i18nKey),
                    nameKey: i18nKey,
                    field: colDef.field,
                    sortable: colDef.sortable === true,
                    params: {}
                };
                if (colDef.group) {
                    col.columnGroup = this._translateService.instant(colDef.group);
                    col.params.group = colDef.group;
                }
                if (colDef.formatter) {
                    col.formatter = this._slickgridFormaterService.getFormatters(colDef.formatter);
                    this.checkFormatterParticularCases(colDef, col, csvExport);
                } else {
                    col.formatter = this._slickgridFormaterService.getColumnFormatter(colDef.field);
                }
                if (colDef.onClick) {
                    col.onCellClick = this._clickService.getCallback(colDef);
                }
                if (colDef.filters) {
                    col.params.filters = colDef.filters;
                }
                if (colDef.dynamic_label) {
                    col.params.dynamic_label = colDef.dynamic_label;
                }
                if (colDef.onClickParam) {
                    col.params.onClickParam = colDef.onClickParam;
                }
                if (colDef.queryField) {
                    col.queryField = colDef.queryField;
                }
                if (colDef.infoButton) {
                    // Replace \n in the character string with a real one \n. Because the \n is not well interpreted in the translation file
                    const tooltipMessage: string = this._translateService.instant(colDef.infoButton).replace(/\\n/g, '\n');
                    col.header = {
                        buttons: [
                          {
                            cssClass: 'info-icon',
                            tooltip: tooltipMessage
                          }
                        ]
                      };
                }
                return col;
            });
    }

    /**
     * Il faut utiliser un formatter dédié pour les colonnes CD_RIB pour éviter que la colonne
     * exportée ne s'affiche comme un nombre dans excel (formatter 15)
     *
     * Il faut également utiliser des formatter spécifiques pour les colonne numériques pour éviter
     * d'afficher le séparateur des milliers (formatter 14 et 16)
     */
    private checkFormatterParticularCases(colDef: ColumnDef, col: Column, csvExport: boolean): void {
        if (csvExport) {
            const numThousandSeparatorFormatterIndex = 6;
            const valDisabledHTMLFormatterIndex = 14;
            const prefixApostropheFormatterIndex = 15;
            const disabledHTMLFormatterIndex = 16;
            const prefixColumns: Array<string> = [
                'CD_CODE_CHAINE',
                'CD_RIB',
                'CD_REF_ARCH',
                'CD_REF_ARCHV',
                'CD_REF_ARCHV_FACT',
                'CD_REF_ARCHV_FACT_COMP',
                'CD_RTS_REF_UNIQ_TRANS',
                'CD_RTS_ID_REGROUP',
                'NU_REF_TRT',
                'NU_DOSS',
                'NU_SIRET',
                'CD_SIEGE_RIB',
                'NU_COMPTE_RIB',
                'CD_RIB_DT_COMSS',
                'CD_DEPARTEMENT',
                'CD_POSTAL',
                'CD_LIB_PORTEUR1',
                'CD_LIB_PORTEUR2',
                'CD_FP_DONNEE_FP',
                'CD_FP_CPLT_AD_SMRCD',
                'CD_FP_ORGA_VT_INDE',
                'CD_FP_ID_FP',
                'CD_FP_ID_MARKET_PLACE',
                'CD_FP_ID_CMRCT_FINAL',
                'CD_DISP_SANS_CONTACT',
                'CD_WAL_DENOM_WALLET',
                'CD_WAL_ID_WALLET',
                'CD_PMV_ID_SOL_PAI_MOB',
                'CD_PMV_TYPO_TRANS',
                'CD_PMV_TYP_PREUVE',
                'CD_TYP_SECU_TRANS_COM_ELEC',
                'CD_DS_RESULT_UTIL_VADS',
                'CD_DS_MOD_SECU_TRANS_MOD',
                'CD_DS_RESUL_ARCHI_PAI_SEC',
                'CD_DS_CRYPTO_COM_ELEC', 
                'CD_DS_V_MAJ_PROTOCOL',
                'CD_DS_UUID_DS_TRANS_ID',
                'CD_DS_UUID_ACS_TRANS_ID', 
                'DT_DS_DAT_AUTHEN',
                'CD_RTS_REF_UNIQ_TRANS',
                'CD_RTS_ID_REGROUP',
                'CD_RTS_M_AUTHEN_PORT',
                'CD_RTS_TYPE_SECU_COM_ELEC',
                'CD_RTS_METHOD_AUTHEN_PORT',
                'CD_RTS_CAL_CRYPT_COM_ELEC',
                'CD_RTS_ARCH_PAI_DIST_SECU',
                'CD_RTS_EXT_ARCH_PAI_SECU',
                'CD_RTS_CRYPT_COM_ELEC',
                'CD_RTS_CAS_PAIEMENT',
                'CD_RTS_ACT_CARD_ONFIL',
                'CD_RTS_ID_EXEMP',
                'CD_RTS_V_MAJ_PROTOCOL',
                'CD_RTS_ACS_TRANS_ID',
                'CD_RTS_DS_TRANS_ID' , 
                'DT_RTS_DAT_AUTHEN', 
                'CD_ICS_PURCHASE_ID_F',
                'CD_ICS_PURCHASE_ID', 
                'CD_ICS_N_ORDR_TRANS_PROV', 
                'CD_ICS_NB_TRANS_PROV', 
                'NU_DOSSIER_OPEN_PAY', 
                'CD_TYPE_FACTURE', 
                'CD_ID_TERMINAL_NLPA', 
                'CD_ID_TERMINAL_IDPA', 
                'CD_ID_PA_OSCAR',
                'CD_DONNEES_PRIV',
                'CD_SIRET_REM',
                'CD_IT_ITP_REM',
                'CD_IT_ID_SYS_AC_REM',
                'CD_IT_ID_NLSA_REM',
                'CD_IT_ID_IDSA_REM',
                'CD_IT_ID_SA_OSCAR_REM',
                'CD_IT_CERT_OSCAR_REM',
                'CD_MOD_LECT_CART',
                'CD_DEB_UNI_REF_ID',
                'NU_PRE_AUT_DUR', 
                'CD_FAC_ACQ_REF_NUM'
            ];
            if (prefixColumns.indexOf(colDef.field) !== -1) {
                col.formatter = this._slickgridFormaterService.getFormatters(prefixApostropheFormatterIndex, csvExport);
            } else if (colDef.formatter === 1) {
                col.formatter = this._slickgridFormaterService.getFormatters(disabledHTMLFormatterIndex, csvExport);
            } else if (colDef.formatter === numThousandSeparatorFormatterIndex) {
                col.formatter = this._slickgridFormaterService.getFormatters(valDisabledHTMLFormatterIndex, csvExport);
            } else {
                col.formatter = this._slickgridFormaterService.getFormatters(colDef.formatter, csvExport);
            }
        }
    }
}
