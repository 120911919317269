/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { GenericChartService } from '@services/generic-chart/generic-chart.service';
import { ChartDataSets, ChartYAxe } from 'chart.js';
import { ChartConf, Series, XAxis, YAxis } from '@services/generic-chart/generic-chart-typings/generic-chart-conf.interface';
import { DateFormatterService } from '@services/formatter/date-formatter.service';
import { NumberFormatterService } from '@services/formatter/number-formatter.service';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { ChartOptionsFilter } from '@services/generic-chart/generic-chart-typings/chart-options.class';

export interface CustomChartDataSets extends ChartDataSets {
    tooltipFormat?: string;
}

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
    @Input() idChart: number;
    @Input() enableTypeSwitch = false;
    @Input() enablePrint = true;

    @Output() switchType: EventEmitter<void> = new EventEmitter();
    @Output() printDashboard: EventEmitter<void> = new EventEmitter();

    public chartConf: ChartConf;
    public labels: string[] = [];
    public datasetsBars: CustomChartDataSets[] = [];
    public datasetsLines: CustomChartDataSets[] = [];
    public yAxis: ChartYAxe[] = [];
    public xAxisLabel = '';
    public chartData: Object[] = [];
    public filters: ChartOptionsFilter[] = [];
    public selectedFilter: ChartOptionsFilter;
    public stacked = false;
    public defaultNumberFormat = '0,0';
    public displayEmptyData = false;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(public chartService: GenericChartService,
        private readonly _dateFormatterService: DateFormatterService,
        private readonly _numberFormatterService: NumberFormatterService,
        private readonly _filterConfigService: FilterConfigService,
        private readonly _translateService: TranslateService) {
    }

    ngOnInit(): void {
        this._createChart();
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public setData(data: Object[]): void {
        this.chartData = data;
        this.datasetsBars = [];
        this.datasetsLines = [];
        this.yAxis = [];
        this.labels = [];
        const series: Series[] = this.chartConf.config.series;
        if (series && series.length > 0) {
            this._initStats(series);
        } else {
            this._initRisk();
        }
        this.xAxisLabel = this._getAxisLabel(this.chartConf.config.xAxis);
        this.displayEmptyData = this.datasetsBars.length === 0 && this.datasetsLines.length === 0;
    }

    public setRiskData(): void {
        this.datasetsBars = [];
        if (this.selectedFilter) {
            this.stacked = (this.selectedFilter.stacked) ? true : false;
        }
        let riskData: Object[] = this.chartData;
        if (this.chartConf.filters?.column && this.selectedFilter) {
            riskData = this.chartData.filter(d => d[this.chartConf.filters.column] === this.selectedFilter.column);
        }
        const seriesData: Object = this._resolveSeriesData(riskData);
        for (const key in seriesData) {
            if (typeof seriesData[key] !== 'undefined') {
                this.datasetsBars.push(seriesData[key]);
            }
        }
        this._setYAxis();
    }

    private _resolveSeriesData(riskData: Object[]): Object {
        const seriesData: Object = {};
        for (const data of riskData) {
            if (!data[this.chartConf.config.yAxis[0].column] || !data[this.chartConf.config.xAxis.splitColumn]) {
                continue;
            }
            const xAxisSplitColumn: string = data[this.chartConf.config.xAxis.splitColumn];
            const labelIndex: number = this.labels.findIndex(label => label === data[this.chartConf.config.xAxis.column]);
            if (typeof seriesData[xAxisSplitColumn] === 'undefined') {
                seriesData[xAxisSplitColumn] = {
                    label: xAxisSplitColumn,
                    data: [],
                    yAxisID: 'y-axis-0',
                    tooltipFormat: this.selectedFilter?.format ? this.selectedFilter.format : this.defaultNumberFormat
                };
            }
            if (this.chartConf.filters?.column || !this.chartConf.filters?.columns) {
                seriesData[xAxisSplitColumn].data[labelIndex] = data[this.chartConf.config.yAxis[0].column];
            } else {
                seriesData[xAxisSplitColumn].data[labelIndex] = data[this.selectedFilter.column];
            }
        }
        return seriesData;
    }

    public print(): void {
        this.printDashboard.next();
    }

    private _initStats(series: Series[]): void {
        for (const serie of series) {
            if (serie.type === 'bar') {
                this.datasetsBars.push({
                    label: this._translateService.instant(serie.label),
                    data: this.chartData.map(d => d[serie.column]),
                    yAxisID: `y-axis-${serie.yAxisIndex}`,
                    tooltipFormat: serie.format
                });
            } else if (serie.type === 'line') {
                this.datasetsLines.push({
                    label: this._translateService.instant(serie.label),
                    data: this.chartData.map(d => d[serie.column]),
                    yAxisID: `y-axis-${serie.yAxisIndex}`,
                    tooltipFormat: serie.format
                });
            }
            if (this.chartConf.config.xAxis.type === 'date') {
                this.labels = this.chartData.map(d =>
                    this._dateFormatterService.value(d[this.chartConf.config.xAxis.column]).format('DATE_SHORT').apply()
                );
            } else if (this.chartConf.config.xAxis.type === 'number') {
                this.labels = this.chartData.map(d =>
                    this._numberFormatterService.value(d[this.chartConf.config.xAxis.column]).format(this.defaultNumberFormat).apply()
                );
            } else {
                // type === string
                this.labels = this.chartData.map(d => d[this.chartConf.config.xAxis.column]);
            }
        }
        this._setYAxis();
    }

    private _initRisk(): void {
        for (const data of this.chartData) {
            if (!data[this.chartConf.config.yAxis[0].column] || !data[this.chartConf.config.xAxis.splitColumn]) {
                continue;
            }
            if (!this.labels.find(label => label === data[this.chartConf.config.xAxis.column])) {
                this.labels.push(data[this.chartConf.config.xAxis.column]);
            }
            if (this.chartConf.filters?.column && !this.filters.find(filter => filter.column === data[this.chartConf.filters.column])) {
                this.filters.push({
                    column: data[this.chartConf.filters.column],
                    label: data[this.chartConf.filters.column],
                    format: this.chartConf.filters?.format ? this.chartConf.filters?.format : this.defaultNumberFormat
                });
            }
        }
        this._initSelectedFilter();
        this._initChartDynamicLabels();
        this.setRiskData();
    }

    private _initSelectedFilter(): void {
        if (this.filters.length > 0) {
            this.selectedFilter = this.filters[0];
        } else if (this.chartConf.filters?.columns) {
            this.filters = this.chartConf.filters.columns;
            this.selectedFilter = this.filters[0];
        }
    }

    private _initChartDynamicLabels(): void {
        if (this.chartConf.filters.dynamicLabel) {
            const filterKeys: string[] = Object.keys(this._filterConfigService.buildValuesHashmap());
            for (const filterKey of filterKeys) {
                if (this.chartConf.filters.dynamicLabel[filterKey]) {
                    this.chartConf.filters.label = this._translateService.instant(this.chartConf.filters.dynamicLabel[filterKey]);
                    break;
                }
            }
        }
    }

    private _setYAxis(): void {
        this.yAxis = [];
        const yAxis = this.chartConf.config.yAxis;
        for (let i = 0; i < yAxis.length; ++i) {
            const yAxisObj: ChartYAxe = {
                id: `y-axis-${i}`,
                type: 'linear',
                position: yAxis[i].position,
                stacked: this.stacked,
                gridLines: {
                    drawBorder: true,
                    drawTicks: false,
                    drawOnChartArea: true
                },
                ticks: {
                    beginAtZero: true,
                    callback: this._formatYAxisLabel.bind(this, yAxis[i].format)
                },
                scaleLabel: {
                    labelString: this._getAxisLabel(yAxis[i]),
                    display: true
                }
            };
            if (this.selectedFilter?.yAxisLabel) {
                yAxisObj.scaleLabel.labelString = this._translateService.instant(this.selectedFilter.yAxisLabel);
            }
            this.yAxis.push(yAxisObj);
        }
    }

    private _createChart(): void {
        this._subscribeChartConfig();
    }

    private _subscribeChartConfig(): void {
        this._subscriptions.add(this.chartService.getChartConfig(this.idChart)
            .subscribe(conf => {
                this.chartConf = conf;
            }));
    }

    private _formatYAxisLabel(format: string, value: number): string {
        if (format) {
            return this._numberFormatterService.value(value).format(format).apply();
        }
        if (this.selectedFilter && this.selectedFilter.yAxisFormat) {
            return this._numberFormatterService.value(value).format(this.selectedFilter.yAxisFormat).apply();
        }
        if (this.selectedFilter && this.selectedFilter.format) {
            return this._numberFormatterService.value(value).format(this.selectedFilter.format).apply();
        }
        if (value % 1 === 0) {
            return this._numberFormatterService.value(value).format(this.defaultNumberFormat).apply();
        }
    }

    private _getAxisLabel(axisConf: XAxis | YAxis): string {
        let axisLabel = '';
        if (axisConf.label) {
            axisLabel = this._translateService.instant(axisConf.label);
        }
        if (axisConf.dynamicLabel) {
            const filterKeys: string[] = Object.keys(this._filterConfigService.buildValuesHashmap());
            for (const filterKey of filterKeys) {
                if (axisConf.dynamicLabel[filterKey]) {
                    axisLabel = this._translateService.instant(axisConf.dynamicLabel[filterKey]);
                    break;
                }
            }
        }
        return axisLabel;
    }
}
