import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterDim, FilterDimValue } from '@components/filters/filter-typings';
import { InstanceIdService } from '@services/instance/instance.service';
import PerfectScrollbar from 'perfect-scrollbar';


export interface TreeSelectItem {
    label: string;
    value: any;
    children: TreeSelectItem[];
    matchSearch: boolean;
    isSelected: (elt: string) => boolean;
    hierarchyLevel?: string;
}

@Component({
    selector: 'app-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.scss'],
    animations: [
        trigger('toggleListContainer', [
            state(
                'opened',
                style({
                    'max-height': '300px',
                    opacity: 1
                }),
            ),
            state(
                'hidden',
                style({
                    height: '0',
                    opacity: 0,
                    transform: 'translateY(-0.25rem)'
                }),
            ),
            transition('hidden => opened', [animate('0.15s 0s ease-in')]),
            transition('opened => hidden', [animate('0.15s 0.15s ease-in')])
        ]),
    ]
})

export class TreeSelectComponent implements OnInit, AfterViewInit {
    @Input() dim: FilterDim;
    @Input() selectedValue: any;
    @Input() selectDisabled: boolean;

    @Output() onSelect: EventEmitter<TreeSelectItem> = new EventEmitter<TreeSelectItem>();

    public componentId: string;
    public dropdownOpen = false;
    public hasValueSelected = false;
    public searchInput: string = '';
    public inputPlaceholder: string = '';
    public iconClass: string = 'i-arrow-down';
    public ps: PerfectScrollbar = null;
    public filteredItemList: TreeSelectItem[] = [];
    public listContainerClass: 'hidden' | 'opened' | 'reduced' = 'hidden';
    public hasMatch: boolean = true;

    private _debounceHolder: NodeJS.Timeout;
    private readonly _debounceDelay = 500;

    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        if (!this._eRef.nativeElement.contains(event.target)) {
            this._closeDropdown();
        }
    }

    constructor(private readonly _eRef: ElementRef, private readonly _instanceService: InstanceIdService) {
        this.componentId = `${this.constructor.name}-${this._instanceService.getId(this)}`;
    }

    ngOnInit(): void {
        this._resetInputState();
        if (this.selectedValue) {
            this.inputPlaceholder = this.selectedValue.label;
            this.hasValueSelected = true;
        }
    }

    ngAfterViewInit(): void {
        this.ps = new PerfectScrollbar(`#${this.componentId}`, { minScrollbarLength: 5 });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.selectedValue?.firstChange && !changes.selectedValue?.currentValue) {
            this._resetInputState();
        }
        if (!changes.selectDisabled?.firstChange && changes.selectDisabled?.currentValue) {
            this._resetInputState();
            this.onSelect.emit(null);
        }
    }

    public openDropdown(): void {
        if (this.selectDisabled) {
            return;
        }
        if (this.filteredItemList.length === 0) {
            this._buildTreeSelectItemList();
            this.ps.update();
        }
        this.dropdownOpen = true;
        this.changeIconClass(new Event('mouseover'));
        this.listContainerClass = 'opened';
    }

    public onItemSelect(selectedItem: TreeSelectItem): void {
        this.inputPlaceholder = selectedItem.label;
        this.hasValueSelected = selectedItem !== null;
        this._closeDropdown();
        this.onSelect.emit(selectedItem);
    }

    public changeIconClass(event: Event): void {
        if (event.type === 'mouseover' && this.hasValueSelected) {
            this.iconClass = 'i-delete-filled';
        } else {
            this.iconClass = this.dropdownOpen ? 'i-search' : 'i-arrow-down';
        }
    }

    public inputUpdate(): void {
        clearTimeout(this._debounceHolder);
        this._debounceHolder = setTimeout(() => {
            this.hasMatch = false;
            this._filterItemList(this.filteredItemList);
            this.ps.update();
            document.querySelector(`#${this.componentId}`).scrollTop = 0;

            this.listContainerClass = this.hasMatch
                ? 'opened'
                : 'reduced';

        }, this._debounceDelay);
    }

    public inputIconClick(event: Event): void {
        if (this.hasValueSelected) {
            event.stopPropagation();
            this._resetInputState();
            this.onSelect.emit(null);
            this.changeIconClass(new Event('mouseover'));
        }
    }

    private _resetInputState(): void {
        this.inputPlaceholder = this.dim?.defaultValue === 'all' ? '_FILTERS_._ALL_' : '';
        this.hasValueSelected = false;
    }

    private _closeDropdown(): void {
        this.searchInput = '';
        this.dropdownOpen = false;
        this.changeIconClass(new Event('mouseout'));
        this.hasMatch = false;
        this._filterItemList(this.filteredItemList);
        this.listContainerClass = 'hidden';
    }

    private _buildTreeSelectItemList(): void {
        this.filteredItemList = [];

        this._addTreeSelectItemChildren(this.filteredItemList, this.dim.values);
    }

    private _addTreeSelectItemChildren(currentItemList: TreeSelectItem[], currentDimValues: FilterDimValue[]): void {
        for (let item of currentDimValues) {
            let newItem: TreeSelectItem = {
                label: item.label,
                value: item.value,
                hierarchyLevel: item.hierarchyLevel,
                matchSearch: true,
                children: [],
                isSelected: (elt: string): boolean => {
                    return elt === this.selectedValue?.value
                }
            };
            if (item.children?.length > 0) {
                this._addTreeSelectItemChildren(newItem.children, item.children);
            }
            currentItemList.push(newItem);
        }
    }

    private _filterItemList(listToFilter: TreeSelectItem[], parentMatch = false): void {
        for (let item of listToFilter) {
            item.matchSearch = item.label.toLowerCase().indexOf(this.searchInput.toLowerCase()) !== -1 || this.searchInput === '' || parentMatch;
            
            if (!this.hasMatch && item.matchSearch) {
                this.hasMatch = true;
            } 

            if (item.children?.length > 0) {
                this._filterItemList(item.children, item.matchSearch);
            }
        }
    }
}
