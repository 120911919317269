/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import JQuery from 'jquery';

import { MenuService } from '@services/menu/menu.service';
import { MenuItem } from '@services/menu/menu-typings';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { AuthService } from '@services/auth/auth.service';
import { DateFormatterService } from '@services/formatter/date-formatter.service';
import { LanguageService } from '@services/language/language.service';
import { QueryService } from '@services/query/query.service';

import { FiltersComponent } from '@components/filters/filters.component';
import { FilterGroup, FilterDim } from '@components/filters/filter-typings';
import { ConfigService } from '@app/services/config/config.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

interface PopperOptions {
    title: () => string;
}

interface JqueryPopper extends JQuery<HTMLElement> {
    tooltip: (options: PopperOptions) => void;
}

export interface AvailableData {
    hours: number;
    minutes: number;
    seconds: number;
    available: boolean;
    availabilityTimeFR: string;
    availabilityTimeEN: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('perimeterFilters') perimeterFilters: FiltersComponent;

    public filterValues: Object = {};
    public filterGroup: FilterGroup = null;
    public applyFilterFunc: () => void = this.applyFilter.bind(this);
    public currentDate: DateTime;
    public displayedCurrentDate = '';
    public structureLabel = '';
    public collapseFilter = true;

    public availableData: { [component: string]: AvailableData | AvailableData[] } = {
        'cash-announcement': {
            hours: 8,
            minutes: 30,
            seconds: 0,
            available: false,
            availabilityTimeFR: '8h30',
            availabilityTimeEN: '8:30am'
        },
        'sales': {
            hours: 11,
            minutes: 0,
            seconds: 0,
            available: false,
            availabilityTimeFR: '11h',
            availabilityTimeEN: '11pm'
        },
        'network-sales': {
            hours: 11,
            minutes: 0,
            seconds: 0,
            available: false,
            availabilityTimeFR: '11h',
            availabilityTimeEN: '11pm'
        },
        'litigation': [
            {
                hours: 15,
                minutes: 0,
                seconds: 0,
                available: false,
                availabilityTimeFR: '15h',
                availabilityTimeEN: '3pm'
            },
            {
                hours: 8,
                minutes: 30,
                seconds: 0,
                available: false,
                availabilityTimeFR: '8h30',
                availabilityTimeEN: '8:30am'
            },
            {
                hours: 14,
                minutes: 0,
                seconds: 0,
                available: false,
                availabilityTimeFR: '14h',
                availabilityTimeEN: '2pm'
            }
        ]
    };

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(public authService: AuthService,
                private readonly _dateFormatterService: DateFormatterService,
                private _menuService: MenuService,
                private readonly _filterConfigService: FilterConfigService,
                private readonly _languageService: LanguageService,
                private readonly _queryService: QueryService,
                private readonly _translateService: TranslateService,
                private readonly _configService: ConfigService,
                private readonly _router: Router) {}

    ngOnInit(): void {
        this._queryService.getSqlResult('home/Q_DT_REF.tpl').subscribe(data => {
            this._updateCurrentDate(data[0]['DT_REFERENCE']);
            this._refreshTilesAvailability();
        });
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(this._formatCurrentDate.bind(this)));
        this._subscriptions.add(this.authService.nbDaysUntilResetObs.subscribe(nbDaysUntilReset => {
            if (nbDaysUntilReset !== null && nbDaysUntilReset <= 0) {
                this._router.navigate(['/view/resetPasswordRequest']);
            }
        }));
    }

    ngAfterViewInit(): void {
        const dateIcon: JqueryPopper = $('.date .icon') as JqueryPopper;
        dateIcon.tooltip({
            title: () => this._translateService.instant('_HOME_._DISPLAY_DATE_')
        });

        this._subscriptions.add(this.authService.authenticated.subscribe(() => {
            if (this.authService.isSuperUser) {
                if (!this.perimeterFilters.filterGroups) {
                    this.perimeterFilters.defaultCollapse = false;
                    this.collapseFilter = false;
                } else {
                    this.perimeterFilters.collapseAllGroup(false);
                }
            }
        }));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public applyFilter(): void {
        this.filterValues = this._filterConfigService.buildValuesHashmap();
        this.structureLabel = '';

        this._updateCurrentDate(this.filterValues['DT_REF']);
        this._refreshTilesAvailability();
        const filterKeys: string[] = Object.keys(this.filterValues);
        if (filterKeys.length > 0) {
            const dimensions: FilterDim[] = this.perimeterFilters?.filterGroups[0]?.filters[0]?.dimensions;
            if (dimensions) {
                const dimRes: FilterDim = dimensions.find(dim => dim.name === filterKeys[0]);
                if (dimRes && dimRes._value) {
                    this.structureLabel = dimRes._value.label;
                }
            }
        }
        this.perimeterFilters.collapseAllGroup(true);
    }

    public redirectTo(url: string): void {
        const menuItem: MenuItem = this._menuService.searchItemByUrl(url, this._menuService.menuItems);
        if (menuItem) {
            this._menuService.selected = menuItem;
            this._menuService.interractItemDashboard(menuItem);
        }
    }

    public toggleFilter(): void {
        this.collapseFilter = !this.collapseFilter;
        this.perimeterFilters.defaultCollapse = !this.perimeterFilters.defaultCollapse;
    }

    public displayContent(): boolean {
        return !this.authService.isSuperUser || Object.keys(this.filterValues).length > 0;
    }

    public print(): void {
        window.print();
    }

    private _updateCurrentDate(dateRef: string): void {
        this.currentDate = DateTime.now(dateRef);
        this._formatCurrentDate();
    }

    private _formatCurrentDate(): void {
        this.displayedCurrentDate = this._dateFormatterService.value(this.currentDate).format('DATE_MED').apply();
    }

    private _refreshTilesAvailability(): void {
        for (const component in this.availableData) {
            if (Array.isArray(this.availableData[component])) {
                const items: AvailableData[] = this.availableData[component] as AvailableData[];
                items.forEach(this._setAvailablity.bind(this));
            } else {
                const item: AvailableData = this.availableData[component] as AvailableData;
                this._setAvailablity(item);
            }
        }
    }

    private _setAvailablity(item: AvailableData): void {
        const date: DateTime = DateTime.now();
        if (this.currentDate.toMillis() < date.toMillis()) {
                item.available = true;
        } else if (this.currentDate.toMillis() >= date.toMillis()) {
            item.available = true;
        } else {
            item.available = false;
        }
    }
}
