/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export interface FilterValueI18nFromDB {
    ID_DIM_REF: number;
    CD_DIM_REF: string;
    CD_REF_VAL: string;
    CD_REF_CD: string;
    CD_DIM_REF_PRNT?: string;
    CD_REF_CD_PRNT_1?: string;
    CD_REF_CD_PRNT_2?: string;
    HIERARCHY_HIDDEN?: string;
    CD_LVL?: string;
}

/* tslint:disable: variable-name check-format */
export class FilterValueI18n {
    id_dim_ref: number;
    cd_dim_ref: string;
    cd_ref_val: string;
    cd_ref_cd: string;
    cd_lang: string;
    cd_dim_ref_prnt?: string;
    cd_ref_cd_prnt?: string;
    hierarchy_hidden?: boolean;
    cd_lvl?: string;

    private readonly _castToBool: string[] = ['hierarchy_hidden'];

    constructor(_: Object) {
        for (const key of Object.keys(_)) {
            const propName: string = key.toLowerCase();
            const value: string | number | boolean = _[key];
            if (value !== null) {
                this[propName] = value;
                if (this._castToBool.includes(propName)) {
                    this[propName] = (value === 'true');
                }
            }
        }
        return this;
    }
}
