/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { first, map } from 'rxjs/operators';
import { MenuItem, MenuItemFromDb } from './menu-typings';
import { ConfigService } from '@services/config/config.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';

export const homeRouterUrl = '/view/home';
export const helpRouterUrl = '/view/help';

interface MenuHashmap { [key: number]: MenuItem; }

@Injectable()
export class MenuService {
    private _menuItems: MenuItem[] = [];
    private _menuHashmap: MenuHashmap = {};
    private readonly _queryTemplate: string;
    private _isLoading = true;
    private _selected?: MenuItem;
    private readonly _homeUrl = '/';
    private readonly _helpUrl = 'help';
    private _homeItem: MenuItem = null;
    private _helpItem: MenuItem = null;
    private _isCollapse = false;

    constructor(private readonly _queryService: QueryService,
        private readonly _configService: ConfigService,
        private readonly _clickService: GenericGridClickService,
        private readonly _router: Router) {
        this._queryTemplate = this._configService.templateGetMenu;
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.id === 1) {
                    this.selectCurrentMenu();
                }
                if (event.url !== event.urlAfterRedirects && event.url !== this._homeUrl && event.url !== this._helpUrl) {
                    for (const menuItem of this._menuItems) {
                        this._resetHighlight(menuItem);
                    }
                    this._selected = null;
                }
                this._clickService.resetRemittance();
            } else if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
                this._selectMenuByUrl(event.url);
            }
        });
    }

    get menuItems(): MenuItem[] { return this._menuItems; }
    set menuItems(value: MenuItem[]) { this._menuItems = value; }

    get isLoading(): boolean { return this._isLoading; }
    set isLoading(flag: boolean) { this._isLoading = flag; }

    get selected(): MenuItem { return this._selected; }
    set selected(item: MenuItem) { this._selected = item; }

    get isCollapse(): boolean { return this._isCollapse; }
    set isCollapse(value: boolean) { this._isCollapse = value; }

    get menuHashmap(): MenuHashmap { return this._menuHashmap; }
    get homeItem(): MenuItem { return this._homeItem; }
    get homeUrl(): string { return this._homeUrl; }
    get helpItem(): MenuItem { return this._helpItem; }
    get helpUrl(): string { return this._helpUrl; }

    public fetchMenu(): Observable<MenuItem[]> {
        if (this._menuItems.length > 0) {
            return of(this._menuItems);
        }
        this._queryService.flushQuery(this._queryTemplate);
        return this._queryService.getSqlResult(this._queryTemplate).pipe(
            first(),
            map((rawMenu: MenuItemFromDb[]) => this._buildMenuItems(rawMenu))
        );
    }

    public searchItemByUrl(url: string, menuItems: MenuItem[]): MenuItem {
        for (const menuItem of menuItems) {
            if (menuItem.url === url) {
                return menuItem;
            }
            if (menuItem.children) {
                const menuChild: MenuItem = this.searchItemByUrl(url, menuItem.children);
                if (menuChild) {
                    return menuChild;
                }
            }
        }
        return null;
    }

    public interractItemDashboard(menuItem: MenuItem, isPage = false): void {
        this.menuItems.forEach(this._resetHighlight.bind(this));
        this._highlightRoot(menuItem);
        const url: string = isPage ? menuItem.url : this._configService.dashboardRootUrl + menuItem.url;
        if (menuItem.url === this._helpUrl) {
            this._router.navigateByUrl(helpRouterUrl);
        } else {
            this._router.navigateByUrl(url);
        }
    }

    public selectItemMenu(menuItem: MenuItem): void {
        if (menuItem.url === this._homeUrl || menuItem.url === this._helpUrl) {
            this.selected = null;
            this.interractItemDashboard(menuItem, true);
        } else {
            this.selected = menuItem;
            this.menuItems.forEach(this._resetHighlight.bind(this));
            this._highlightRoot(menuItem);
        }
    }

    public selectCurrentMenu(): void {
        this._selectMenuByUrl(this._router.url);
    }

    public getBreadCrumb(idMenuItem: number): string[] {
        const breadCrumb: string[] = [];
        if (!this.selected) {
            return [];
        }
        this._addToBreadCrumb(idMenuItem, breadCrumb);
        return breadCrumb;
    }

    private _resetHighlight(menuItem: MenuItem): void {
        menuItem.highlight = false;
        if (menuItem.children) {
            menuItem.children.forEach(this._resetHighlight.bind(this));
        }
    }

    private _highlightRoot(menuItem: MenuItem): void {
        if (menuItem.isRoot) {
            menuItem.highlight = true;
        } else {
            this._highlightRoot(this.menuHashmap[menuItem.idPrnt]);
        }
    }

    private _buildMenuItems(rawMenu: MenuItemFromDb[]): MenuItem[] {
        rawMenu.forEach((menuElem: MenuItemFromDb) => {
            this._menuHashmap[menuElem.ID_MENU] = new MenuItem(menuElem);
            if (!menuElem.ID_MENU_PARENT) {
                this._menuItems.push(this._menuHashmap[menuElem.ID_MENU]);
                if (this._menuHashmap[menuElem.ID_MENU].url === this._homeUrl) {
                    this._homeItem = this._menuHashmap[menuElem.ID_MENU];
                }
                if (this._menuHashmap[menuElem.ID_MENU].url === this._helpUrl) {
                    this._helpItem = this._menuHashmap[menuElem.ID_MENU];
                }
            } else {
                this._menuHashmap[menuElem.ID_MENU_PARENT].addChild(this._menuHashmap[menuElem.ID_MENU]);
            }
        });
        return this._menuItems;
    }

    private _addToBreadCrumb(idMenuItem: number, breadCrumb: string[]): void {
        breadCrumb.unshift(this.menuHashmap[idMenuItem].code);
        if (this.menuHashmap[idMenuItem].idPrnt) {
            this._addToBreadCrumb(this.menuHashmap[idMenuItem].idPrnt, breadCrumb);
        }
    }

    private _selectMenuByUrl(url: string): void {
        const formattedUrl: string = url.replace('/reports/', '');
        const menuItem: MenuItem = this.searchItemByUrl(formattedUrl, this.menuItems);
        if (menuItem) {
            this.selectItemMenu(menuItem);
        } else if (url === helpRouterUrl) {
            this.selectItemMenu(this.helpItem);
        } else if (url === homeRouterUrl) {
            this.selectItemMenu(this.homeItem);
        }
    }
}
