/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ColumnDef } from './generic-grid-typings';
import { OnEventArgs } from 'angular-slickgrid';
import { Contract } from '@components/contract/contract.component';
import { DateTime } from 'luxon';

type SLEvent = KeyboardEvent | MouseEvent;
type ClickCallbackType = (e: SLEvent, args: OnEventArgs) => void;
type ModelType = Contract;

interface Remittance {
    ID_GRID: number;
    NU_REM: number;
    NU_SEQUENCE: number;
    NU_CONTRAT: number;
    DT_CONSTRUCTION: string;
    CD_SIGN_REMITTANCE: string;
}

export enum GridClickCallback {
    CONTRACT = 'contract',
    REMITTANCE = 'remittance'
}

@Injectable({
    providedIn: 'root'
})
export class GenericGridClickService {
    private _models: {[key: string]: ModelType} = {
        [GridClickCallback.CONTRACT]: null
    };
    private readonly _callbacks: {[key: string]: ClickCallbackType} = {
        [GridClickCallback.CONTRACT]: this._openContractModal.bind(this),
        [GridClickCallback.REMITTANCE]: this._searchRemittance.bind(this)
    };
    private _remittance: Remittance = null;

    get isModalOpen(): boolean {
        let modalOpen = true;
        Object.keys(this._models).forEach(key => {
            if (this._models[key] === null) {
                modalOpen = false;
            }
        });
        return modalOpen;
    }
    get selectedContract(): Contract { return this._models[GridClickCallback.CONTRACT]; }
    set selectedContract(contract: Contract) { this._models[GridClickCallback.CONTRACT] = contract; }

    get selectedRemittance(): Remittance { return this._remittance; }

    public getCallback(col: ColumnDef): ClickCallbackType {
        if (typeof this._callbacks[col.onClick] === 'undefined') {
            throw new Error(`OnClick callback ${col.onClick} not found on column ${col.field}`);
        }
        return this._callbacks[col.onClick];
    }

    public closeAllModals(): void {
        Object.keys(this._models).forEach(key => this._models[key] = null);
    }

    public resetRemittance(): void {
        this._remittance = null;
    }

    private _openContractModal(e: SLEvent, args: OnEventArgs): void {
        this._models[GridClickCallback.CONTRACT] = args.dataContext;
    }

    private _searchRemittance(e: SLEvent, args: OnEventArgs): void {
        this._remittance = {
            ID_GRID: args.columnDef.params.onClickParam,
            NU_REM: args.dataContext.NU_REM,
            NU_SEQUENCE: args.dataContext.NU_SEQUENCE,
            NU_CONTRAT: args.dataContext.NU_CONTRAT,
            DT_CONSTRUCTION: DateTime.fromISO(args.dataContext.DT_CONSTRUCTION).toFormat('yyyy-MM-dd'),
            CD_SIGN_REMITTANCE: args.dataContext.CD_SIGN_REMITTANCE
        };
    }
}
