/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { enUS, fr } from 'date-fns/locale';
import { Settings } from 'luxon'; 
import numeral from 'numeral';

import { FilterValueI18n } from '@components/filters/filter-typings';
import { QueryService } from '@services/query/query.service';
import { ConfigService } from '@services/config/config.service';
import { FilterValueI18nFromDB } from '@app/components/filters/filter-typings/filter-i18n.class';

interface I18nValuesTemplateParam {
    CD_LANG: string;
    ONLY_FETCH_LABELS: boolean;
}

interface DateFormat {
    language: string;
    format: string;
}

@Injectable()
export class LanguageService {
    private readonly _languages: string[] = ['en', 'fr'];
    private _filterI18n: Object;
    private _filterValues: FilterValueI18n[] = [];
    private _i18nValuesTemplate: string;
    private readonly _languageFormats: DateFormat[] = [
        { language: this._languages[0], format: 'yyyy/MM/dd' },
        { language: this._languages[1], format: 'dd/MM/yyyy' }
    ];

    private readonly _translationLoaded: Subject<void> = new Subject();
    private readonly _selectableLanguageLimit = 2;
    private _hierarchyFetched = false;

    constructor(
        private readonly translate: TranslateService,
        private readonly _queryService: QueryService,
        private readonly _configService: ConfigService,
        private readonly _i18n: NzI18nService) {
            this._i18nValuesTemplate = this._configService.templateGetI18n;
    }

    get languages(): string[] { return this._languages; }
    get currentLang(): string { return this.translate.currentLang; }
    get translationLoaded(): Observable<void> { return this._translationLoaded.asObservable(); }

    public init(): Observable<Object[]> {
        const defaultUserLang: string = this._getCurrentUserLanguage();

        this.translate.addLangs(this._languages);
        for (const language of this._languages) {
            // tslint:disable-next-line:no-require-imports
            this.translate.setTranslation(language, require(`../../../i18n/${language}.json`), true);
        }
        this.translate.setDefaultLang(defaultUserLang);
        this.translate.use(defaultUserLang);
        this._i18n.setLocale(defaultUserLang === 'fr' ? fr_FR : en_US);
        this._i18n.setDateLocale(defaultUserLang === 'fr' ? fr : enUS);
        return this.loadFilterValuesTranslations();
    }

    public setLanguage(language: string): Observable<Object[]> {
        if (this._languages.indexOf(language) > -1) {
            this.translate.use(language);
            this._i18n.setLocale(language === 'fr' ? fr_FR : en_US);
            this._i18n.setDateLocale(language === 'fr' ? fr : enUS);
            return this.loadFilterValuesTranslations();
        } else {
            const errorMessage = `ERROR -> LanguageService : ${language} is not a known language`;
            if (isDevMode()) {
                console.error(errorMessage);
            }
            return throwError(errorMessage);
        }
    }

    public getFilterValues(dimName: string): FilterValueI18n[] {
        return this._filterValues.reduce((acc: FilterValueI18n[], val: FilterValueI18n) => {
            if (val.cd_dim_ref === dimName && val.cd_lang === this.translate.currentLang) {
                acc.push(val);
            }
            return acc;
        }, []);
    }

    public loadFilterValuesTranslations(): Observable<Object[]> {
        this._filterI18n = this._languages.reduce((acc, lang) => {
            acc[lang] = {};
            return acc;
        }, {});
        Settings.defaultLocale = this.currentLang;
        numeral.locale(this.currentLang);
        const queryParams: I18nValuesTemplateParam = { CD_LANG: this.currentLang, ONLY_FETCH_LABELS: this._hierarchyFetched };
        this._queryService.flushQuery(this._i18nValuesTemplate, queryParams);
        return this._queryService.getSqlResult(this._i18nValuesTemplate, queryParams)
            .pipe(
                first(),
                tap((data: FilterValueI18nFromDB[]) => {
                    this._emptyFilterValues();
                    data.forEach(row => this._generateFilterValuesTranslations(row));
                    this._languages.forEach(lang => this.translate.setTranslation(lang, this._filterI18n[lang], true));
                    this._hierarchyFetched = true;
                    this._translationLoaded.next();
                })
            );
    }

    public getLanguageFormat(language: string): string {
        return this._languageFormats.find(languageFormat => languageFormat.language === language).format;
    }

    /**
     * Build hierarchical translations
     * CD_REF_CD_PRNT_1 can be a value of "CD_GROUPE_AFFAIRE", "CD_ENSEIGNE" or "CD_ENTITE_JURIDIQUE"
     * CD_REF_CD_PRNT_2 is set only for contrat and rib level with "CD_CODE_CHAINE" value.
     */
    private _generateFilterValuesTranslations(dataRow: FilterValueI18nFromDB): void {
        const parent1: string = dataRow.CD_REF_CD_PRNT_1;
        const parent2: string = dataRow.CD_REF_CD_PRNT_2;
        delete dataRow.CD_REF_CD_PRNT_1;
        delete dataRow.CD_REF_CD_PRNT_2;
        dataRow['CD_REF_CD_PRNT'] = parent1;
        dataRow['CD_LANG'] = this.translate.currentLang;
        this._insertFilterValuesTranslations(new FilterValueI18n(dataRow));
        if (parent2) {
            dataRow['CD_REF_CD_PRNT'] = parent2;
            this._insertFilterValuesTranslations(new FilterValueI18n(dataRow));
        }
    }

    private _insertFilterValuesTranslations(dataRow: FilterValueI18n): void {
        this._filterValues.push(dataRow);
        this._filterI18n[dataRow.cd_lang][dataRow.cd_dim_ref] = this._filterI18n[dataRow.cd_lang][dataRow.cd_dim_ref] ?? {};
        this._filterI18n[dataRow.cd_lang][dataRow.cd_dim_ref][dataRow.cd_ref_cd] = dataRow.cd_ref_val;
    }

    private _getCurrentUserLanguage(): string {
        const defaultAppLanguage = 'fr';
        let defaultUserLang: string = navigator.language || defaultAppLanguage;

        if (defaultUserLang.length > this._selectableLanguageLimit) {
            defaultUserLang = defaultUserLang.substring(0, this._selectableLanguageLimit);
        }
        if (this._languages.indexOf(defaultUserLang) === -1) {
            defaultUserLang = defaultAppLanguage;
        }
        return defaultUserLang;
    }

    private _emptyFilterValues(): void {
        if (!this._hierarchyFetched) {
            this._filterValues = [];
        } else {
            const hierarchyDims: Array<string> = ['CD_ENTITE_JURIDIQUE', 'CD_CODE_CHAINE', 'NU_CONTRAT', 'CMT_RIB'];
            const tmpFilterValues: FilterValueI18n[] = this._filterValues.filter(
                filterValue => hierarchyDims.indexOf(filterValue.cd_dim_ref) !== -1
            );
            this._filterValues = [];
            tmpFilterValues.forEach(filterValue => {
                filterValue.cd_lang = this.currentLang;
                this._insertFilterValuesTranslations(filterValue);
            });
        }
    }
}
