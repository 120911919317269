/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';

import { AngularSlickgridModule } from 'angular-slickgrid';
import { TranslateModule } from '@ngx-translate/core';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { en_US, NZ_I18N, NZ_DATE_CONFIG, fr_FR } from 'ng-zorro-antd/i18n';

import { ConfigService } from '@services/config/config.service';
import { ErrorService } from '@services/error/error.service';
import { ExportService } from '@services/export/export.service';
import { FilterConfigBuilderService } from '@services/filter-config-builder/filter-config-builder.service';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';
import { LanguageService } from '@services/language/language.service';
import { MenuService } from '@services/menu/menu.service';
import { QueryService } from '@services/query/query.service';
import { AuthService } from '@services/auth/auth.service';
import { GenericChartService } from '@services/generic-chart/generic-chart.service';
import { InstanceIdService } from '@services/instance/instance.service';

import { FilterComponent } from '@components/filters/filter-group/filter/filter.component';
import { FilterDimensionDateComponent } from '@components/filters/filter-group/filter/filter-dimension-date/filter-dimension-date.component';
import { FilterDimensionHierarchicalSelectComponent } from '@components/filters/filter-group/filter/filter-dimension-hierarchical-select/filter-dimension-hierarchical-select.component';
import { FilterDimensionRadioComponent } from '@components/filters/filter-group/filter/filter-dimension-radio/filter-dimension-radio.component';
import { FilterDimensionSelectComponent } from '@components/filters/filter-group/filter/filter-dimension-select/filter-dimension-select.component';
import { FilterDimensionValueComponent } from '@components/filters/filter-group/filter/filter-dimension-value/filter-dimension-value.component';
import { FilterGroupComponent } from '@components/filters/filter-group/filter-group.component';
import { FiltersComponent } from '@components/filters/filters.component';

import { ChartMixedComponent } from '@components/charts/chart-mixed/chart-mixed.component';
import { ChartLegendComponent } from '@components/charts/chart-legend/chart-legend.component';

import { MenuComponent } from '@components/menu/menu.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { NavBarComponent } from '@components/nav-bar/nav-bar.component';
import { LayoutComponent } from '@components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { GridComponent } from '@components/grid/grid.component';
import { ContractComponent } from '@components/contract/contract.component';
import { ContractContainerComponent } from '@components/contract/contract-container/contract-container.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HomeComponent } from './view/home/home.component';
import { HelpComponent } from './view/help/help.component';
import { SalesComponent } from '@components/home/sales/sales.component';
import { NetworkSalesComponent } from '@components/home/network-sales/network-sales.component';
import { LitigationComponent } from '@components/home/litigation/litigation.component';
import { CashAnnouncementComponent } from '@components/home/cash-announcement/cash-announcement.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { ErrorComponent } from '@components/error/error.component';
import { MaxRowsReachedComponent } from '@components/max-rows-reached/max-rows-reached.component';
import { EmptyDataComponent } from '@components/home/empty-data/empty-data.component';
import { ChartComponent } from '@components/chart/chart.component';
import { ReportPlaceholderComponent } from '@components/report-placeholder/report-placeholder.component';
import { ReportHeaderComponent } from '@components/report-header/report-header.component';
import { ContactComponent } from '@components/contact/contact.component';
import { AlertBrowserComponent } from '@components/alert-browser/alert-browser.component';
import { AlertStructureComponent } from '@components/alert-structure/alert-structure.component';
import { AlertPasswordExpirationComponent } from '@components/alert-password-expiration/alert-password-expiration.component';
import { TreeSelectComponent } from '@components/tree-select/tree-select.component';
import { TreeSelectItemComponent } from '@components/tree-select/tree-select-item/tree-select-item.component';

registerLocaleData(en);
registerLocaleData(fr);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularSlickgridModule,
    NzDatePickerModule,
    NzSelectModule,
    NzTreeSelectModule,
    RouterModule,
    ScrollingModule,
    TranslateModule.forChild()
  ],
  declarations: [
    FilterComponent,
    FilterDimensionDateComponent,
    FilterDimensionHierarchicalSelectComponent,
    FilterDimensionRadioComponent,
    FilterDimensionSelectComponent,
    FilterDimensionValueComponent,
    FilterGroupComponent,
    FiltersComponent,

    ChartMixedComponent,
    ChartLegendComponent,

    GridComponent,
    LayoutComponent,
    MenuComponent,
    NavBarComponent,
    PaginationComponent,
    ContractComponent,
    ContractContainerComponent,
    FooterComponent,
    HelpComponent,
    HomeComponent,
    SalesComponent,
    NetworkSalesComponent,
    LitigationComponent,
    CashAnnouncementComponent,
    LoaderComponent,
    ErrorComponent,
    MaxRowsReachedComponent,
    EmptyDataComponent,
    ChartComponent,
    ReportPlaceholderComponent,
    ReportHeaderComponent,
    ContactComponent,
    AlertBrowserComponent,
    AlertStructureComponent,
    AlertPasswordExpirationComponent,
    TreeSelectComponent,
    TreeSelectItemComponent
  ],
  exports: [
    FilterComponent,
    FilterDimensionDateComponent,
    FilterDimensionHierarchicalSelectComponent,
    FilterDimensionRadioComponent,
    FilterDimensionSelectComponent,
    FilterDimensionValueComponent,
    FilterGroupComponent,
    FiltersComponent,

    ChartMixedComponent,
    ChartLegendComponent,

    GridComponent,
    LayoutComponent,
    MenuComponent,
    NavBarComponent,
    PaginationComponent,
    ContractComponent,
    ContractContainerComponent,
    FooterComponent,
    HelpComponent,
    HomeComponent,
    SalesComponent,
    NetworkSalesComponent,
    LitigationComponent,
    CashAnnouncementComponent,
    LoaderComponent,
    ErrorComponent,
    MaxRowsReachedComponent,
    EmptyDataComponent,
    ChartComponent,
    ReportPlaceholderComponent,
    ReportHeaderComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ConfigService,
        ErrorService,
        ExportService,
        FilterConfigService,
        FilterConfigBuilderService,
        FilterDimValueService,
        LanguageService,
        MenuService,
        QueryService,
        AuthService,
        GenericChartService,
        InstanceIdService,
        {
          provide: NZ_I18N,
          useFactory: (localId: string) => {
            switch (localId) {
              case 'en':
                return en_US;
              case 'fr':
                return fr_FR;
              default:
                return fr_FR;
            }
          },
          deps: [LOCALE_ID]
        },
        { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } }
      ]
    };
  }
}
