/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { LanguageService } from '@services/language/language.service';
import { ComponentGeneratorService } from '@services/component-generator/component-generator.service';
import { QueryService } from '@services/query/query.service';
import { SlickgridFormatterService } from '@services/slickgrid-formatter/slickgrid-formatter.service';
import { ErrorService } from './services/error';

/* tslint:disable: component-selector */
@Component({
    selector: 'app',
    template: `<router-outlet></router-outlet>
               <app-loader *ngIf="queryService.isLoading()"></app-loader>
               <app-error *ngIf="error"></app-error>`
})

export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
    /**
     * Display an error only when get_menu.tpl or get_i18n failed.
     * Except for Access Denied (403) error. The user will be redirect to login page (Manage in query service)
     */
    public error = false;

    private readonly _subscriptions: Subscription = new Subscription();
    private readonly _accessDeniedCode = 403;

    constructor(public queryService: QueryService,
                private readonly _changeDetector: ChangeDetectorRef,
                private readonly _componentGeneratorService: ComponentGeneratorService,
                private readonly _languageService: LanguageService,
                private readonly _router: Router,
                private readonly _formatter: SlickgridFormatterService,
                private readonly _errorService: ErrorService) {
                    this.error = this._errorService.hasError;
    }

    ngOnInit(): void {
        this._subscriptions.add(forkJoin([
            this._languageService.init(),
            this._componentGeneratorService.initMenu(),
            this._formatter.initFormatter()
        ]).subscribe({
            error: (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status !== this._accessDeniedCode) {
                    this.error = this._errorService.hasError;
                } else {
                    this._router.initialNavigation();
                }
            },
            complete: () => {
                this._router.initialNavigation();
            }
        }));
    }

    ngAfterViewChecked(): void {
        this._changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
       this._subscriptions.unsubscribe();
    }
}
